<template>
  <div class="lotscan-orderscnt">
    <header class="inner-head bottom-border">
      <div class="left-col">
        <Span class="icon-btn" @handleClick="goBack">
          <back-icon />
        </Span>
        <h1>Return Orders</h1>
      </div>
      <div-wrapper class="rgt-iconbox">
        <span @click="showHelpModal = true" class="icon-Btn help-btn">
          <help-icon />
        </span>
      </div-wrapper>
    </header>
    <section class="mid-cnt">
      <!-- <button @click="isVerifyProduct = true">Open modal</button> -->
      <div id="unScanned" class="tab-cnt lot-listing">
        <div class="filter-srch" v-if="orders.length">
          <Input
            iType="text"
            :value="search"
            name="search"
            :placeholder="$t('_searchHere')"
            class="input-srch"
            @input="getSearchInput"
          />
          <search-icon />
        </div>
        <div class="lot-titlebox" v-if="orders.length">
          <Span :txt="$t('_totalPacket')">
            :
            <strong>{{ filteredList.length }}</strong>
          </Span>
          <Span>
            <strong></strong>
          </Span>
        </div>
        <no-order-msg v-if="!orders.length" :message="$t('_noOrder')" />
        <ul v-else>
          <li v-for="(order, _index) in filteredList" :key="_index">
            <div-wrapper :style="{ margin: '8px' }">
              <button class="button" @click="openPhotoViewer(order.orderId)">
                {{ $t("_seePhoto") }}
              </button>
            </div-wrapper>
            <Order :order="order">
              <template>
                <div class="tag_block">
                  <Span
                    v-if="order.is_missing"
                    className="flag-tag"
                    :txt="$t('_missing')"
                  />
                  <Span
                    className="flag-tag"
                    :style="{ background: '#ff9933' }"
                    v-if="order.is_deny_redispatch"
                    :txt="`${$t('_forRedispatch')}`"
                  />
                  <Span
                    className="flag-tag"
                    :style="{ background: '#ff9933' }"
                    v-if="order.is_return"
                    :txt="$t('_returnPackage')"
                  />
                  <slot></slot>
                  <Span
                    className="button dispute-btn"
                    :txt="$t('_cancelPickup')"
                    @handleClick="cancelPickup(order)"
                  />
                </div>
              </template>
            </Order>
          </li>
        </ul>
      </div>
    </section>
    <div class="scantab-ftr" v-if="orders.length">
      <div class="scan-tab" @click="goToScanScreen">
        <span class="scan-elm">
          <scan-icon />
          <br />
          <span class="text-label">{{ $t("_scan") }}</span>
        </span>
      </div>
    </div>
    <Scanner
      v-if="isScannerOpen"
      @orderId="getOrderId"
      @closeScanner="isScannerOpen = !isScannerOpen"
    />
    <order-status-modal
      v-if="isImageEditorOpen"
      @closeModal="isImageEditorOpen = !isImageEditorOpen"
    >
      <div class="barmodelbox scan-msgbox">
        <img :src="orderImage" alt="order image" width="100%" height="640" />
      </div>
    </order-status-modal>
    <confirmation-modal
      v-if="shouldPickupCancel"
      @action="getConfirmationAction"
      :btnTxt="$t('_yes')"
    >
      <div-wrapper className="confim-midcnt" slot="body">
        <p>
          {{ $t("_cancelPickupConfirmationText") }}
        </p>
      </div-wrapper>
    </confirmation-modal>
    <common-modal
      v-if="isPickupDescriptionModalOpen"
      @action="getCancelPickupMessage"
      @handle-close-click="
        isPickupDescriptionModalOpen = !isPickupDescriptionModalOpen
      "
      btnTxt="Cancel Pickup"
      btnClass="cancel-btn"
    >
      <div-wrapper className="confim-midcnt cancel-form" slot="body">
        <div class="field-row">
          <label class="text-label">Description</label>
          <textarea
            class="textare-style"
            name="description"
            @change="handleDescription"
          >
          </textarea>

          <span class="error-msg" v-if="error">{{ error }}</span>
        </div>
      </div-wrapper>
    </common-modal>
    <DisputeModal
      v-if="isDisputeModalOpened"
      :order="disputedOrder"
      @closeModal="isDisputeModalOpened = !isDisputeModalOpened"
    />
    <help-modal
      @closeModal="showHelpModal = false"
      :text="toBePickedOrdersHelpText"
      v-if="showHelpModal"
    ></help-modal>

    <Popup :message="message" @handleClick="message = ''" />
    <Loader v-if="isLoading" />
    <verify-product-open
      v-if="isVerifyProduct"
      :orderId="orderId"
      @closeModal="closeVerifyModal"
    />
  </div>
</template>
<script>
import {
  getFilteredOrders,
  // getOrderIndex,
  getSortedOrderByDate,
} from "../utils/OrderHelper";
import { BASE_API, DISPUTE_SUB_CATEGORIES } from "../utils/constants";
import { GET, POST } from "../utils/HttpStatusCodeConst";
import ApiRequest from "../services/ApiRequest";
import DivWrapper from "@/components/form/DivWrapper";
import SearchIcon from "@/components/svgIcons/SearchIcon";
import NoOrderMsg from "@/components/order/NoOrderMsg";
import HelpModal from "@/components/modals/HelpModal";
import BackIcon from "@/components/svgIcons/BackIcon";
import ScanIcon from "@/components/svgIcons/ScanIcon";
import HelpIcon from "@/components/svgIcons/HelpIcon";
import Loader from "@/components/commonUI/LoaderWrapper";
import Input from "@/components/form/InputWrapper";
import Order from "@/components/order/Order";
import Span from "@/components/other/Span";
import VerifyProductOpen from "../components/VerifyProductOpen.vue";
import Axios from "axios";
import { SessionHelper, X_AUTH_ID } from "../services/Session";

export default {
  components: {
    ConfirmationModal: () => import("@/components/modals/ConfirmationModal"),
    CommonModal: () => import("@/components/modals/CommonModal"),
    OrderStatusModal: () => import("@/components/modals/OrderStatusModal"),
    DisputeModal: () => import("@/components/modals/DisputeModal"),
    Scanner: () => import("@/components/Scanner"),
    Popup: () => import("@/components/Popup"),
    NoOrderMsg,
    DivWrapper,
    SearchIcon,
    HelpModal,
    BackIcon,
    ScanIcon,
    HelpIcon,
    Loader,
    Input,
    Order,
    Span,
    VerifyProductOpen,
  },

  data() {
    return {
      toBePickedOrdersHelpText: this.$t("_toBePickedOrdersHelpText"),
      isPickupDescriptionModalOpen: false,
      currentCancelPickupOrder: null,
      isDisputeModalOpened: false,
      shouldPickupCancel: false,
      isImageEditorOpen: false,
      showHelpModal: false,
      isScannerOpen: "",
      disputedOrder: "",
      orderImage: null,
      isLoading: false,
      description: "",
      message: "",
      search: "",
      orders: [],
      error: "",
      isVerifyProduct: false,
      orderId: null,
    };
  },

  computed: {
    filteredList() {
      return getFilteredOrders(this.orders, this.search);
    },
  },

  created() {
    // this.$router = new RouteHelper(this.$router);
    this.getOrdersPickedFromCustomer();
  },

  methods: {
    closeVerifyModal() {
      this.isVerifyProduct = !this.isVerifyProduct;
      this.getOrdersPickedFromCustomer();
    },
    goBack() {
      this.$router.back(-1);
    },

    goToScanScreen() {
      this.isScannerOpen = !this.isScannerOpen;
    },

    getSearchInput(_event) {
      this[_event.target.name] = _event.target.value;
    },

    raiseDispute(_order) {
      this.disputedOrder = _order;
      this.isDisputeModalOpened = !this.isDisputeModalOpened;
    },

    handleDescription(e) {
      this[e.target.name] = e.target.value;
    },

    cancelPickup(_cancelPickupOrder) {
      this.currentCancelPickupOrder = _cancelPickupOrder;
      this.shouldPickupCancel = !this.shouldPickupCancel;
    },

    getConfirmationAction(_action) {
      this.shouldPickupCancel = !this.shouldPickupCancel;
      if (_action === "no") {
        return;
      }
      this.isPickupDescriptionModalOpen = !this.isPickupDescriptionModalOpen;
    },

    getCancelPickupMessage() {
      if (!this.description) {
        this.error = "Please write cancel pickup message!";
        return;
      }
      this.proceedCancelPickup();
    },

    proceedCancelPickup() {
      this.isLoading = !this.isLoading;
      ApiRequest(BASE_API, `/raise-dispute`, POST, {
        orderId: this.currentCancelPickupOrder.order_id,
        description: this.description,
        remark: "Customer refuse to take order",
        type: "pickup_cancel",
        subcategory: DISPUTE_SUB_CATEGORIES.returnOrders,
      })
        .then((_data) => {
          this.isLoading = !this.isLoading;
          this.isPickupDescriptionModalOpen = !this
            .isPickupDescriptionModalOpen;
          if (_data.result.status) {
            this.getOrdersPickedFromCustomer();
            this.$swal({
              text: _data.result.Message || "Pickup Cancel Successfully",
              icon: "success",
            });
          } else
            this.$swal({
              text: _data.result.Message || this.$t("_pleaseTryAgain"),
              icon: "error",
            });
        })
        .catch(() => {
          this.isLoading = !this.isLoading;
          this.$swal({ text: this.$t("_goToDashboardTry"), icon: "error" });
        });
    },

    async openPhotoViewer(_orderId) {
      this.isLoading = !this.isLoading;
      await ApiRequest(
        "https://groupbuybe.payboard.in/",
        `orderService/order-by-id/${_orderId}`,
        GET
      )
        .then((_data) => {
          this.isLoading = !this.isLoading;
          this.orderImage = _data.subOrderDTOList[0].itemImage;
        })
        .catch(() => {
          this.isLoading = !this.isLoading;
          this.$swal({ text: this.$t("_pleaseTryAgain"), icon: "error" });
        });
      this.isImageEditorOpen = !this.isImageEditorOpen;
    },

    async getOrdersPickedFromCustomer() {
      this.isLoading = !this.isLoading;
      const { data } = await Axios.get(
        `https://groupbuybe.payboard.in/orderService/pt91/get-order-for-partner91?limit=100&offset=0`,
        {
          headers: {
            "x-auth-id": SessionHelper.getCookie(X_AUTH_ID),
          },
        }
      );
      this.orders = getSortedOrderByDate(data.orderDTOs);
      this.isLoading = !this.isLoading;
      // ApiRequest(
      //   BASE_API,
      //   `/getorders/?order_type=to_be_picked&is_return=true`,
      //   GET
      // )
      //   .then((_data) => {
      //     this.isLoading = !this.isLoading;
      //     this.orders = getSortedOrderByDate(_data.order_details);
      //   })
      //   .catch(() => {
      //     this.isLoading = !this.isLoading;
      //     this.$swal({ text: this.$t('_goToDashboardTry'), icon: 'error' });
      //   });
    },

    getOrderId(_orderId) {
      this.isLoading = !this.isLoading;
      console.log(_orderId);
      this.isScannerOpen = !this.isScannerOpen;
      console.log(this.orders);
      let order = this.orders.find((data) => data.orderId == _orderId);
      if (order) {
        this.isVerifyProduct = true;
        this.orderId = _orderId;
      } else {
        this.$swal({
          text: this.$t("_orderNotFound", {
            orderId: this._orderId,
          }),
          icon: "error",
        });
      }
      this.isLoading = !this.isLoading;
      // ApiRequest(
      //   BASE_API,
      //   `/scanorder/${_orderId}?action=to_be_picked`,
      //   GET
      // )
      //   .then((_data) => {
      //     this.isLoading = !this.isLoading;
      //     this.isScannerOpen = !this.isScannerOpen;
      //     if (!_data.status) {
      //       this.$swal({
      //         text:
      //           _data.Message ||
      //           this.$t('_orderNotFound', {
      //             orderId: this._orderId,
      //           }),
      //         icon: 'error',
      //       });
      //     } else {
      //       const index = getOrderIndex(
      //         this.orders,
      //         'order_id',
      //         _orderId.toString()
      //       );
      //       if (index > -1) this.orders.splice(index, 1);
      //       // this.getOrdersPickedFromCustomer();
      //       this.$swal({
      //         text: this.$t('_orderPickedFromCustomer', {
      //           orderId: this._orderId,
      //         }),
      //         icon: 'success',
      //       });
      //     }
      //   })
      //   .catch(() => {
      //     this.isLoading = !this.isLoading;
      //     this.isScannerOpen = !this.isScannerOpen;
      //     this.$swal({
      //       text: this.$t('_somethingWentWrongScanOrderAgain'),
      //       icon: 'error',
      //     });
      //   });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_order.scss";
// .redispatch-flag {
//   background: #ff9933;
//   margin-left: 8px;
// }
// .bottom-border {
//   border-bottom: 1px solid #dddddd;
// }
</style>
