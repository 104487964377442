<template>
    <div-wrapper
        className="overlay"
        :class="{ open: isOpen }"
    >
        <div-wrapper
            id="verify-product"
            className="verify-product"
            :class="{ open: isOpen }"
        >
        <!-- <span class="close-icon" @click="closeModal">
            <close-icon/>
        </span> -->
            Your item has been scanned successfully !

            <div-wrapper className="is-product-open">
                <p class="ask-user">Is this product has been open ?</p>
                <div-wrapper class="options">
                    <label for="yes" class="option">
                        <input type="radio" id="yes" name="isProductOpen" value="yes" v-model="isProductOpen"> <span>Yes</span>
                    </label>
                    <label for="no" class="option">
                        <input type="radio" id="no" name="isProductOpen" value="no" v-model="isProductOpen"> <span>No</span>
                    </label>
                </div-wrapper>
                <button class="submit-btn" @click="handleSubmit">Submit</button>
            </div-wrapper>
        </div-wrapper>
    </div-wrapper>
</template>

<script>
import ApiRequest from '../services/ApiRequest';
import { BASE_API } from '../utils/constants';
import { GET } from '../utils/HttpStatusCodeConst';
import DivWrapper from './form/DivWrapper.vue'
// import CloseIcon from './svgIcons/CloseIcon.vue'
export default {
    props: ['orderId'],
  components: {
        DivWrapper,
        // CloseIcon 
    },
  data() {
     return {
        isProductOpen: "no",
        isOpen: true
      }
  },
  methods: {
    // closeModal() {
    //     this.$emit("closeModal")
    // }
    handleSubmit() {
        let checkProdStatus = false;
        if(this.isProductOpen == 'yes'){
            checkProdStatus = true;
        }
        console.log(checkProdStatus);
        
        ApiRequest(BASE_API, `/partner91/scanorder/${this.orderId}?is_open=${checkProdStatus}`, GET)
            .then(data => {
                console.log(data);
                this.$emit("closeModal")
            })
            .catch((e) => {
                console.log(e);
            })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_common.scss";
.overlay{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 11;
    background: rgba(0, 0, 0, 0.8);
    visibility: hidden;
    opacity: 0;
    transition: all ease 0.3s;
    &.open {
        visibility: visible;
        opacity: 1;
    }
    .verify-product {
        width: 22em;
        border-radius: 10px;
        text-align: center;
        background: white;
        padding: 2.5em 1em;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    
        .close-icon{
            position: absolute;
            right: .4em;
            top: .6em;
            svg{
                height: 20px;
            }
        }
        .is-product-open{
            .ask-user{
                padding: .8em 0;
            }
            .options {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 0.875em;
                width: 20em;
                margin: auto;
                .option {
                    display: flex;
                    align-items: center;
                    background: white;
                    padding: 1em 4em 1em 1em;
                    border: 1px solid #d9d9d9;
                    border-radius: 0.6em;
                span {
                    padding-left: 0.7em;
                }
                input[type="radio"]:after {
                    width: 14px;
                    height: 14px;
                    border-radius: 15px;
                    top: -2px;
                    left: -1px;
                    position: relative;
                    background-color: #fff;
                    border: 0.1em solid #d1d3d1;
                    content: "";
                    display: inline-block;
                    visibility: visible;
                }
    
                input[type="radio"]:checked:after {
                    width: 16px;
                    height: 16px;
                    border-radius: 15px;
                    top: -2px;
                    left: -1px;
                    position: relative;
                    background-color: rgb(1, 160, 1);
                    content: "\2714";
                    text-align: center;
                    color: #fff;
                    font-size: 0.8em;
                    display: inline-block;
                    visibility: visible;
                    // border: 2px solid white
                }
                }
            }
            .submit-btn{
                outline: none;
                padding: .8em 1.3em;
                margin: 2em 0 0 0;
                border-radius: 6px;
                border: none;
                background: #4D2BAD;
                color: white;
            }
        }
    }
}
</style>