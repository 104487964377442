<template>
  <div :class="[{ boxShadow: isBoxShadow }]" @click="$emit('handleOrderClick')">
    <MinOrderDetail :order="order" old>
      <div class="right-col" v-if="order.order_payment_status === 'COD'">
        <cod-icon />
        <br />
        {{ order.order_payment_status }}
      </div>
    </MinOrderDetail>
    <div class="mid-card">
      <div class="dtl-box">
        <h2>{{ $t("_name") }}</h2>
        <p>{{ order.addressDTO.personName }}</p>
      </div>
      <div class="dtl-box">
        <h2>{{ $t("_mobileNumber") }}</h2>
        <p>
          <a :href="`tel:+91 ${order.customer_phone}`"
            >+91 {{ order.addressDTO.contactNumber }}</a
          >
        </p>
      </div>
      <div class="dtl-box">
        <h2>{{ $t("_address") }}</h2>
        <p>{{ order.addressDTO.combinedAddress }}</p>
      </div>
      <div class="dtl-box">
        <h2>
          {{
            order.paymentMethod === "COD"
              ? `COD ${$t("_amount")}`
              : `${$t("_amount")}`
          }}
        </h2>
        <span class="amt-val">
          Rs. {{ order.order_amount }}
          {{
            order.paymentMethod === "COD" ? `${$t("_toCollect")}` : ""
          }}
        </span>
      </div>
      <slot name="dispute"></slot>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import MinOrderDetail from "@/components/order/MinOrderDetail";
import CodIcon from "@/components/svgIcons/CodIcon";
export default {
  components: {
    MinOrderDetail,
    CodIcon,
  },

  props: {
    order: {
      type: Object,
    },
    isBoxShadow: {
      type: Boolean,
      default: false,
    },
    old: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_order.scss";
.boxShadow {
  border-radius: 7px;
  box-shadow: 0 2px 3px 0 rgba(60, 64, 67, 0.3),
    0 6px 10px 4px rgba(60, 64, 67, 0.15);
}
</style>
