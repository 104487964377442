<template>
  <div-wrapper className="top-card">
    <div-wrapper className="left-col">
      <div-wrapper className="text-info">
        <Span :txt="$t('_orderID')"> :</Span>
        <Span :txt="order.orderId.toString()" />
      </div-wrapper>
      <div-wrapper
        className="text-info"
        v-if="order.order_delivery_date || order.order_return_date"
      >
        <Span :txt="$t('_deliveryDate')"> :</Span>
        <Span
          :txt="
            new Date(
              order.order_delivery_date
                ? order.order_delivery_date
                : order.order_return_date
            ).toDateString()
          "
        />
      </div-wrapper>
    </div-wrapper>
    <slot></slot>
  </div-wrapper>
</template>
<script>
import DivWrapper from "@/components/form/DivWrapper";
import Span from "@/components/other/Span";
export default {
  components: {
    DivWrapper,
    Span
  },

  props: {
    order: {
      type: Object
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_order.scss";
</style>
